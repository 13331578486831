* {
  margin: 0;
}

.app {
  text-align: center;
}

.video {
  align-items: center;
  align-content: center;
  align-self: center;
}
.Container {
  padding: 50px 20px;
  max-width: 1200px;
  margin: auto;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Column1, .Column2 {
  flex: 1;
  margin: 10px;
  max-width: 500px;
}

.Card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
}

.CardItems {
  margin: 20px 0;
}

.ArtistImg {
  width: 100%;
  border-radius: 10px;
}

.HeroContainer {
  position: relative;
  text-align: center;
  color: white;
}

.LoopVideo {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.VideoBg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.HeroContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ArtistNameHero {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
}

.PlayerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.PlayerLogo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.PlayerContent {
  flex: 1;
}
